import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert, { ALERT_MESSAGE } from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MiniLoader from "../../components/shared/MiniLoader"
import { Dropdown, Segment, Header, Divider, Input, Button } from "semantic-ui-react"
import { getStyles } from "../../components/shared/StylesheetInjector"
import ApprovalsHistory from "./ApprovalsHistory"
import { Approvals, constants } from "caradvise_shared_components"
import {
  formattedApprovalContacts,
  formattedCancelationContacts,
  eligibleForCancelation
} from "../../helpers/activeMaintenanceHelpers"
import * as phoneNumberHelpers from "../../helpers/phoneNumberHelpers"
import * as numberHelpers from "../../helpers/numberHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { isTBC, isAch, isBraintree, selectOptions } from "../../helpers/userHelpers"
import { Redirect } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileInvoiceDollar, faPencil } from "@fortawesome/pro-light-svg-icons"
import { formattedAddress } from "../../helpers/shopHelpers"
import {
  isDTCAFleet,
  isFleetAdvise,
  isFleetcor,
  isFleetMaintenanceHubCanada,
  isZubie
} from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
import PromotionalCodeLink from "../../components/services/PromotionalCodeLink"
import PaymentsIndex from "../payments/PaymentsIndex"
import CustomNotification from "../../components/shared/CustomNotification"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import { isDriver } from "../../helpers/userRoleHelpers"
import { isNull } from "lodash"
import ApproversNotesHistory from "./ApproversNotesHistory"
import ApprovalsShowShimmer from "./ApprovalsShowShimmer"
import ApprovalsShowPaymentSectionShimmer from "./ApprovalsShowPaymentSectionShimmer"
import { NA } from "../../constants/paymentActivity"
import {
  CREDIT_CARD_WITH_ACH_LABEL,
  ACH_LABEL,
  CREDIT_CARD_LABEL
} from "../../constants/achPayment"
import {
  gaJSON,
  logFirebaseAnalyticsScreenName,
  orderApprovedEvent,
  trackFirebaseAnalytics
} from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"
import { capitalCase } from "../../helpers/stringHelpers"
import PaymentMethod from "../PaymentMethods/PaymentMethod"
import { COMPLETED_LABEL, getOrderStatus } from "../../helpers/orderHelpers"
import AddVanityLink from "../../components/services/AddVanityLink"
import ShowManualManintenanceHistoryModal from "../../components/shared/ShowManualManintenanceHistoryModal"
import { APPROVAL_STATUS, COMPLETED, PAID } from "../../constants/statuses"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { setupSplitFlags } from "../../components/config/SplitClient"
import { isEmpty } from "lodash"
import { clearStateAfterTimeout } from "../../helpers/documentHelpers"
import { Image } from "semantic-ui-react"

const { Approvals3Form } = Approvals
const serviceStatuses = constants.services.statuses
let styles = getStyles()
const { INITIATED_BY_USER, ACTIVE, AWAITING_APPROVAL, APPROVED_LABEL } = constants.orders
const { ACH_TYPE, BRAINTREE, BRAINTREE_ACH, WEX, FLEETCOR } = constants.paymentProviders
styles = { ...styles, primaryColor: styles.carAdviseOrange }
class ApprovalsShow extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    order: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      alertMessage: "",
      alertType: "default",
      editingPo: false,
      poNumber: (props.order || {}).po_number || "",
      isSubmitting: false,
      infoModalOpen: true,
      selectedPaymentProvider: "",
      showMaintenanceHistory: false,
      isVanityLabelSubmitting: {},
      isApprovalNotesSubmitting: false,
      braintree_payment_methods: [],
      paymentScreen: false,
      selectedPaymentMethod: null,
      approveAll: false,
      declinedAll: false,
      loadingPaymentMethods: false,
      services: [],
      updateVanityForServiceId: null,
      vanityName: "",
      creditCardId: null,
      onApprovalChangeServices: []
    }
    this.props.services &&
      this.props.services.map((service) => {
        this.state.isVanityLabelSubmitting[service.id] = false
      })
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    this.orderPreview({ withPriceRanges: true, set_default_order_services_status: true })
    this.orderPreviewDetails()
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: "SHOP_ORDER_UNLOAD_SUCCEEDED"
    })
    dispatch({
      type: "RESET_VEHICLE_MAINTENANCE_HISTORIES"
    })
  }

  componentDidUpdate(prevProps) {
    const { fleet, order } = this.props
    if (
      (isBraintree(fleet) || isAch(fleet)) &&
      order &&
      order.braintree_payment_methods &&
      order.braintree_payment_methods.length != 0 &&
      this.state.braintree_payment_methods != order.braintree_payment_methods
    ) {
      let default_payment_method = order.braintree_payment_methods.find(
        (payment) => payment["is_default"] === true
      )
      this.setState({ braintree_payment_methods: order.braintree_payment_methods })
      default_payment_method &&
        this.setState({
          selectedPaymentMethod: default_payment_method,
          selectedPaymentProvider:
            default_payment_method.type == ACH_TYPE ? BRAINTREE_ACH : BRAINTREE,
          creditCardId: default_payment_method.token
        })
    }
    if (this.state.services.length == 0 && this.props.services) {
      this.setState({ services: this.props.services })
    }
  }

  onCancel = () => {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_CANCELATION_SAGA",
      payload: {
        submissionData: {
          id: this.props.order.id,
          status: "cancel"
        }
      },
      callback: this.afterSubmit
    })
  }

  updateLoadingState = (id, newValue) => {
    this.setState((prevState) => ({
      isVanityLabelSubmitting: { ...prevState.isVanityLabelSubmitting, [id]: newValue }
    }))
  }

  onVanitySubmit = (index, service, vanityLabel) => {
    const { dispatch, t } = this.props

    this.updateLoadingState(index, true)
    const response = dispatch({
      type: "CREATE_AND_UPDATE_VANITY_LABEL_SAGA",
      payload: {
        order_service_id: service.id,
        shop_order_id: service.order_id,
        vanity_label: vanityLabel,
        isNew: isNull(service.vanity_label),
        t: t,
        index: index
      },
      callback: this.afterVanitySubmitRequest.bind(this)
    })
  }

  afterVanitySubmitRequest = (status, data) => {
    if (status === NOTIFICATION_TYPE.SUCCESS) {
      this.setState({
        updateVanityForServiceId: data.response.order_service_id,
        vanityName: data.response.vanity_label
      })
      this.onRequestVanitySubmitSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  resetVanityData = () => {
    this.setState({ updateVanityForServiceId: null, vanityName: "" })
  }

  onRequestVanitySubmitSuccess = (data) => {
    this.onRequestSuccess(data)
  }

  afterRequest(status, data) {
    if (status === NOTIFICATION_TYPE.SUCCESS) {
    } else {
      this.onRequestFailure(data)
    }
  }

  onRequestFailure(data) {
    const { type, title, message, index } = data
    this.updateLoadingState(index, false)
    this.setState({ isApprovalNotesSubmitting: false })
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  onRequestSuccess(data) {
    const { type, title, message, index } = data
    this.updateLoadingState(index, false)
    this.setState({ isApprovalNotesSubmitting: false })
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  onSubmit = (services, customerComments, creditCardId, approverNote) => {
    const { currentUser, order, t } = this.props
    const { fleet_payment_providers, pays_through_platform, wex_account_number } = currentUser
    const { selectedPaymentProvider, poNumber } = this.state
    creditCardId = this.state.creditCardId
    let paymentProvider = selectedPaymentProvider

    if (pays_through_platform) {
      if (selectedPaymentProvider) {
        if (paymentProvider === BRAINTREE && creditCardId === null) {
          CustomNotification(
            NOTIFICATION_TYPE.DANGER,
            t("common:approvalErrorLabel"),
            t("common:selectCardErrorLabel"),
            t
          )
          return
        } else if (paymentProvider === "wex" && !wex_account_number) {
          CustomNotification(
            NOTIFICATION_TYPE.DANGER,
            t("common:approvalErrorLabel"),
            t("common:selectWexErrorLabel"),
            t
          )
          return
        }
      } else {
        CustomNotification(
          NOTIFICATION_TYPE.DANGER,
          t("common:approvalErrorLabel"),
          t("common:noPaymentProvider"),
          t
        )
        return
      }
    }
    this.setState({ isSubmitting: true }, () => {
      if (approverNote && approverNote.length > 0) {
        this.setState({ isApprovalNotesSubmitting: true })
        this.props.dispatch({
          type: "SHOP_ORDER_APPROVERS_NOTES_CREATE_SAGA",
          payload: {
            shopOrderId: order.id,
            shopId: order.shop_id,
            approverNote: approverNote,
            t: t
          }
        })
      }

      this.props.dispatch({
        type: "MAINTENANCE_APPROVAL_SAGA",
        payload: {
          submissionData: {
            id: order.id,
            order_services: services,
            customer_comments: customerComments,
            po_number: poNumber ? poNumber : order.poNumber,
            payment_token: creditCardId,
            payment_type: paymentProvider
          }
        },
        callback: this.afterSubmit
      })
    })
  }

  afterSubmit = (status, data) => {
    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  onApprovalChange = async (services) => {
    const { order, currentUser } = this.props
    await this.setState({ loadingSums: true })
    await this.setState({ isSubmitting: true })
    this.orderPreview({ services: services })
    this.setState({ approveAll: false, declinedAll: false, onApprovalChangeServices: services })
  }

  afterShopOrderLoad = async (status, data) => {
    this.setState({ loadingSums: false })
    this.setState({ isSubmitting: false })

    if (data.state == APPROVAL_STATUS || data.state == PAID || data.state == COMPLETED) {
      this.setState({ loadingPaymentMethods: true })
      this.loadUserDetails()
    } else {
      if (this.props.services && this.props.services.every((service) => service.status === 2))
        this.setState({ approveAll: true, declinedAll: false })
      if (this.props.services && this.props.services.every((service) => service.status === 3))
        this.setState({ declinedAll: true, approveAll: false })
    }
  }

  fetchOrderData = (options = {}) => {
    this.props.dispatch({
      type: "SHOP_ORDER_LOAD_SAGA",
      payload: {
        shopOrderId: this.props.match.params.id,
        orderServices: options.services,
        priceAsApprovalsLogic: true,
        withPriceRanges: options.withPriceRanges
      },
      callback: this.afterShopOrderLoad.bind(this)
    })
  }

  orderPreview = (options = {}) => {
    this.props.dispatch({
      type: "SHOP_ORDER_LOAD_SAGA",
      payload: {
        shopOrderId: this.props.match.params.id,
        orderServices: options.services,
        priceAsApprovalsLogic: true,
        withPriceRanges: options.withPriceRanges,
        set_default_order_services_status: options.set_default_order_services_status
      },
      callback: this.afterShopOrderLoad.bind(this)
    })
  }

  orderPreviewDetails = () => {
    this.props.dispatch({
      type: "SHOP_ORDER_LOAD_DETAILS_SAGA",
      payload: {
        shopOrderId: this.props.match.params.id
      },
      callback: this.afterShopOrderLoad.bind(this)
    })
  }

  loadUserDetails = () => {
    this.props.dispatch({
      type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA",
      payload: {},
      callback: this.afterLoadPaymentData
    })
  }

  afterLoadPaymentData = () => {
    this.setState({ loadingPaymentMethods: false })
  }

  onSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false,
      isApprovalNotesSubmitting: false
    })

    window.scrollTo(0, 0)
  }

  async onSubmitSuccess(data) {
    if (data && data.response) {
      await trackFirebaseAnalytics({
        event: gaJSON.payment_selected,
        params: {
          [gaJSON.payment_type]:
            data.response.payment_type && capitalCase(data.response.payment_type)
        }
      })
      orderApprovedEvent(data.response)
    }
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false,
      submitted: true,
      isApprovalNotesSubmitting: false
    })
  }

  toggleEditingPo = () => this.setState({ editingPo: !this.state.editingPo })
  setPo = (e) => this.setState({ poNumber: e.target.value })
  closeInfoModal = () => this.setState({ infoModalOpen: !this.state.infoModalOpen })

  maintenaceHistoryPageView = () => {
    this.setState({ showMaintenanceHistory: true })
    const section = document.getElementById("maintenance-summary-section")

    if (section.style.display === "none") section.style.display = "block"
    else section.style.display = "none"

    if (window.innerWidth <= 1138) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth"
      })
    }
  }

  approvalsInfoModal = () => {
    const { infoModalOpen } = this.state
    const { order, services } = this.props

    return <></>
  }

  handleSubmitPoNumber = () => {
    const { order, dispatch } = this.props

    this.setState({ isSubmitting: true })
    dispatch({
      type: "MAINTENANCE_UPDATE_SAGA",
      payload: {
        submissionData: { id: order.id, po_number: this.state.poNumber }
      },
      callback: () => this.setState({ isSubmitting: false, editingPo: false })
    })
  }

  handleCancelPoNumberEdit = () => {
    this.setState({ editingPo: false, poNumber: this.props.order.po_number || "" })
  }

  renderPoNumber = () => {
    const { order, t } = this.props
    const { editingPo, poNumber, isSubmitting } = this.state

    if (editingPo) {
      return (
        <>
          <Input value={poNumber || order.po_number} onChange={this.setPo} />
          {isSubmitting ? (
            <MiniLoader inverted={false} />
          ) : (
            <>
              <span
                style={{ paddingLeft: "7px" }}
                className="link"
                disabled={!isSubmitting}
                onClick={this.handleSubmitPoNumber}
              >
                {t("common:saveLabel")}
              </span>
              &nbsp;&nbsp;
              <span className="link" onClick={this.handleCancelPoNumberEdit}>
                {t("common:cancelLabel")}
              </span>
            </>
          )}
        </>
      )
    } else {
      return (
        <>
          {order.po_number || NA}
          <FontAwesomeIcon
            style={{ paddingLeft: "7px", cursor: "pointer" }}
            icon={faPencil}
            onClick={this.toggleEditingPo}
          />
        </>
      )
    }
  }

  orderInfo = () => {
    const { order, users, t } = this.props
    const { shop } = order || {}
    const currentUser = this.props.currentUser || this.state.transitionalCurrentUser || {}
    const user = order.originator || {}

    return (
      <>
        <div className="inline-elements">
          <Header size="large" className="title">
            {vehicleHelpers.formattedName(order.vehicle)}
          </Header>
          {!isDriver(currentUser) ? (
            <div className="action">
              <span className="link" onClick={() => this.maintenaceHistoryPageView()}>
                {t("vehicle:viewMaintenanceHistoryLbl")}{" "}
              </span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <p>
          {t("dashboard:shopOrderId")}: {order.id}
        </p>
        <p>
          {t("dashboard:otherIdLabel")}: {order.vehicle.other_id || NA}
        </p>
        <p>
          {t("poNumberLabel")}:{this.renderPoNumber()}
        </p>
        <p>
          <div>
            <strong>{t("shopLabel")}:</strong>
          </div>
          {`${shop.name} - ${formattedAddress(shop)}`}
          <div className={"active_order__detail"}>
            {phoneNumberHelpers.reformat(shop.main_phone_number)}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("appointmentLabel")}:</strong>
          </div>
          {`${order.appointment_date_pretty} ${order.appointment_time_pretty}`}
        </p>
        <p>
          <div>
            <strong>{t("odometerLabel")}: </strong>

            {isFleetMaintenanceHubCanada()
              ? `${numberHelpers.formatWithCommas(order.kilometers)} km`
              : `${numberHelpers.formatWithCommas(order.miles)} mi`}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("customerLabel")}: </strong>
            {user.name}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("cellLabel")}: </strong>
            {phoneNumberHelpers.reformat(user.cellPhone)}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("emailLabel")}: </strong>
            {user.email}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("userRolesLabel")}: </strong>
            {user.roles.map((r) => (typeof r === "string" ? r : r.name)).join(", ")}
          </div>
        </p>
        <Divider hidden />
        {order.state === AWAITING_APPROVAL && !order.can_approve && (
          <Segment>
            <p>{t("notEligibleToApproveLabel")}:</p>
            {order.approver_user_ids &&
              formattedApprovalContacts(order, users)
                .split("\n")
                .map((c) => <p>{c}</p>)}
          </Segment>
        )}
        <Divider hidden />
      </>
    )
  }

  onPaymentProviderChange = (value) => {
    this.setState({
      selectedPaymentProvider: value
    })
  }

  wexPaymentsPage() {
    return {
      name: "wex",
      label: "wexPaymentLabel"
    }
  }

  braintreePaymentsPage(braintree_label) {
    return {
      name: "braintree",
      label: braintree_label
    }
  }

  fleetcorPaymentsPage() {
    return {
      name: "fleetcor",
      label: "fleetcorLabel"
    }
  }

  selectPaymentProvidersView() {
    const { currentUser, fleet } = this.props
    const { fleet_payment_providers } = currentUser
    let pages = []
    let braintree_label = CREDIT_CARD_LABEL

    if (isBraintree(fleet) && isAch(fleet)) braintree_label = CREDIT_CARD_WITH_ACH_LABEL
    else if (isBraintree(fleet)) braintree_label = CREDIT_CARD_LABEL
    else if (isAch(fleet)) braintree_label = ACH_LABEL

    if (
      fleet_payment_providers &&
      (fleet_payment_providers.includes(BRAINTREE) ||
        fleet_payment_providers.includes(BRAINTREE_ACH))
    )
      pages.push(this.braintreePaymentsPage(braintree_label))
    if (fleet_payment_providers && fleet_payment_providers.includes(WEX))
      pages.push(this.wexPaymentsPage())
    if (fleet_payment_providers && fleet_payment_providers.includes(FLEETCOR))
      pages.push(this.fleetcorPaymentsPage())
    return (
      <PaymentsIndex
        currentUser={currentUser}
        pages={pages}
        fromApproval={true}
        onChange={this.onPaymentProviderChange}
        {...this.props}
      />
    )
  }

  onApproversNotesSubmit = (order, approverNote) => {
    const { dispatch, t } = this.props
    const { id, shop_id } = order

    this.setState({ isApprovalNotesSubmitting: true })
    dispatch({
      type: "SHOP_ORDER_APPROVERS_NOTES_CREATE_SAGA",
      payload: {
        shopOrderId: id,
        shopId: shop_id,
        approverNote: approverNote,
        t: t
      },
      callback: this.afterRequest.bind(this)
    })
  }

  continueToPayment = () => {
    this.setState({ loadingPaymentMethods: true, paymentScreen: true })
    this.loadUserDetails()
  }

  backToApprovals = () => {
    this.setState({ paymentScreen: false })
  }

  getAffiliation = () => {
    if (isFleetcor()) return "Fuelman Maintenance."
    if (isZubie()) return "Zubie Smart Maintenance."
    if (isDTCAFleet()) return "Discount Tire Fleet Services."
    return ""
  }

  renderPayments = () => {
    const { order, currentUser, t } = this.props
    const { fleet_payment_providers, pays_through_platform, wex_account_number } = currentUser
    const { selectedPaymentMethod, selectedPaymentProvider } = this.state
    let orderStatus = getOrderStatus(order)
    return pays_through_platform ? (
      <div
        className="scrollable-div"
        style={{
          minHeight:
            orderStatus == APPROVED_LABEL || orderStatus == COMPLETED_LABEL ? "55px" : "150px",
          height: orderStatus == APPROVED_LABEL || orderStatus == COMPLETED_LABEL ? "70px" : "150px"
        }}
      >
        {fleet_payment_providers &&
        (fleet_payment_providers.includes(BRAINTREE) ||
          fleet_payment_providers.includes(BRAINTREE_ACH))
          ? order &&
            order.braintree_payment_methods &&
            order.braintree_payment_methods.map((pm) => (
              <PaymentMethod
                key={pm.id}
                pm={pm}
                onSelectPayment={this.onSelectPayment}
                selectedPaymentMethod={selectedPaymentMethod}
                providerType={BRAINTREE}
                selectedPaymentProvider={selectedPaymentProvider}
                orderStatus={orderStatus}
              />
            ))
          : null}
        {fleet_payment_providers &&
        fleet_payment_providers.includes(WEX) &&
        ((orderStatus != APPROVED_LABEL && orderStatus != COMPLETED_LABEL) ||
          (order.payment_type == WEX &&
            (orderStatus == APPROVED_LABEL || orderStatus == COMPLETED_LABEL) &&
            wex_account_number)) ? (
          <PaymentMethod
            onSelectPayment={this.onSelectPayment}
            selectedPaymentMethod={selectedPaymentMethod}
            providerType={WEX}
            selectedPaymentProvider={selectedPaymentProvider}
            orderStatus={orderStatus}
            wex_account_number={wex_account_number}
          />
        ) : null}
        {fleet_payment_providers &&
        fleet_payment_providers.includes(FLEETCOR) &&
        ((orderStatus != APPROVED_LABEL && orderStatus != COMPLETED_LABEL) ||
          (order.payment_type == FLEETCOR &&
            (orderStatus == APPROVED_LABEL || orderStatus == COMPLETED_LABEL))) ? (
          <PaymentMethod
            onSelectPayment={this.onSelectPayment}
            selectedPaymentMethod={selectedPaymentMethod}
            providerType={FLEETCOR}
            selectedPaymentProvider={selectedPaymentProvider}
            orderStatus={orderStatus}
          />
        ) : null}
        {fleet_payment_providers &&
        ((fleet_payment_providers.length == 1 &&
          (fleet_payment_providers.includes(BRAINTREE) ||
            fleet_payment_providers.includes(BRAINTREE_ACH))) ||
          (fleet_payment_providers.length == 2 &&
            fleet_payment_providers.includes(BRAINTREE) &&
            fleet_payment_providers.includes(BRAINTREE_ACH))) &&
        order &&
        order.braintree_payment_methods &&
        order.braintree_payment_methods.length == 0 ? (
          <span>{t("shopOrders:addPaymentInSettingsLabel")}</span>
        ) : null}
        {!fleet_payment_providers && pays_through_platform ? (
          <span>{t("shopOrders:addPaymentInSettingsLabel")}</span>
        ) : null}
      </div>
    ) : null
  }

  onSelectPayment = (payment_method = null, provider_type) => {
    if (provider_type == BRAINTREE) {
      this.setState({ selectedPaymentMethod: payment_method, creditCardId: payment_method.token })
      this.setState({
        selectedPaymentProvider: payment_method.type === ACH_TYPE ? BRAINTREE_ACH : BRAINTREE
      })
    } else if (provider_type == WEX)
      this.setState({ selectedPaymentMethod: null, selectedPaymentProvider: WEX })
    else if (provider_type == FLEETCOR)
      this.setState({ selectedPaymentMethod: null, selectedPaymentProvider: FLEETCOR })
  }

  updateAllServices = async (services, flag) => {
    if (flag == true) this.setState({ approveAll: true, declinedAll: false })
    else this.setState({ declinedAll: true, approveAll: false })
    await this.setState({ loadingSums: true })
    await this.setState({ isSubmitting: true })
    this.orderPreview({ services: services })
  }

  renderVanityComponent = (service, index) => {
    const { isVanityLabelSubmitting } = this.state

    return (
      <AddVanityLink
        service={service}
        onVanitySubmit={this.onVanitySubmit}
        isVanityLabelSubmitting={isVanityLabelSubmitting}
        canEditVanity={!isDriver(this.props.currentUser)}
        index={index}
        t={this.props.t}
      />
    )
  }

  renderViewmaintenanceHistoryLink = () => {
    const { t } = this.props
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ShowManualManintenanceHistoryModal {...this.props}>
          <div className="action">
            <span className="link">{t("vehicle:viewMaintenanceHistoryLbl")} </span>
          </div>
        </ShowManualManintenanceHistoryModal>
      </div>
    )
  }

  renderViewReceiptButton = () => {
    const { t, order } = this.props
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {[COMPLETED, PAID].includes(order.status) && (
          <Button onClick={() => window.open(`/orders/${order.id}/approvals/invoice`, "_blank")}>
            View Receipt
          </Button>
        )}
      </div>
    )
  }

  savingsComponent = () => {
    const { t, order } = this.props
    return order.total_savings > order.transaction_fee ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1px",
          fontSize: "16px",
          backgroundColor: "#F1F1F1",
          borderRadius: "4px",
          justifyContent: "space-between",
          background: "white",
          width: "100%",
          alignItems: "center"
        }}
      >
        <div style={{ padding: 20, width: "90%" }}>
          <p>
            <span style={{ fontWeight: "bold" }} className="primary-text-color">
              You saved ${order.total_savings}
            </span>
            <span style={{ fontWeight: "normal" }}>
              {" "}
              by using CarAdvise {isFleetAdvise() && "for Business."}
              {!isFleetAdvise() && "through " + this.getAffiliation()}
            </span>
          </p>
        </div>
        <div className="primary-background-color piggy-bank">
          <Image
            src={"/assets/images/PiggyBank.png"}
            style={{ width: "80px", marginTop: "5px", margin: "auto" }}
          />
        </div>
      </div>
    ) : null
  }

  renderContent() {
    const { users, order, priceRanges, t, i18n, currentUserSession } = this.props
    const { services } = this.state
    const currentUser = this.props.currentUser || this.state.transitionalCurrentUser || {}
    const {
      pays_through_platform,
      has_payment_method,
      fleet_payment_providers,
      wex_account_number
    } = currentUser
    const {
      isSubmitting,
      submitted,
      loadingSums,
      selectedPaymentProvider,
      isVanityLabelSubmitting,
      isApprovalNotesSubmitting,
      paymentScreen,
      approveAll,
      declinedAll,
      loadingPaymentMethods,
      updateVanityForServiceId,
      vanityName,
      onApprovalChangeServices,
      showTirePromotionBannerConfig,
      showTirePromotionBannerFlag
    } = this.state
    const RejectionReasonsDropdown = (
      <Dropdown
        className="rejection-reasons-dropdown"
        placeholder={t("seletRejectionReasonLabel")}
        fluid
        selection
      />
    )

    const CreditCardDropdown = (
      <Dropdown className="credit-card-dropdown" placeholder="Select Credit Card" fluid selection />
    )

    if (submitted) return <Redirect to={navigationPaths.dashboard()} />

    if (services && services.length > 0) {
      const {
        braintree_payment_methods,
        check_payment_method_of_previous_transaction,
        pre_tax_sub_total,
        tax_amount,
        previous_payments
      } = order
      if (braintree_payment_methods && braintree_payment_methods.length > 0) {
        currentUser.has_cc_info_saved = true
      } else if (
        fleet_payment_providers &&
        fleet_payment_providers.length == 1 &&
        (isBraintree(currentUser) || isAch(currentUser)) &&
        braintree_payment_methods &&
        braintree_payment_methods.length == 0
      ) {
        currentUser.has_cc_info_saved = false
      }

      if (fleet_payment_providers && fleet_payment_providers.includes("fleetcor")) {
        currentUser.has_cc_info_saved = true
      }
      // To manage the refund scenario
      if (
        order &&
        check_payment_method_of_previous_transaction &&
        Number(pre_tax_sub_total) + Number(tax_amount) - Number(previous_payments) <= 0
      ) {
        if (check_payment_method_of_previous_transaction.toLowerCase() == BRAINTREE) {
          currentUser.fleet_payment_providers = [BRAINTREE]
        }
        if (check_payment_method_of_previous_transaction.toLowerCase() == BRAINTREE_ACH) {
          currentUser.fleet_payment_providers = [BRAINTREE_ACH]
        }
        if (check_payment_method_of_previous_transaction.toLowerCase() == WEX) {
          currentUser.fleet_payment_providers = [WEX]
        }
        if (check_payment_method_of_previous_transaction.toLowerCase() == FLEETCOR) {
          currentUser.fleet_payment_providers = [FLEETCOR]
        }
      }
      return (
        <div>
          <section className="container-approvals">
            <div className="box-container">
              <div>
                <Approvals3Form
                  allCreditCardsInfo={braintree_payment_methods || []}
                  isSubmitting={isSubmitting}
                  onSubmit={this.onSubmit}
                  onCancel={this.onCancel}
                  eligibleForCancelation={eligibleForCancelation(order)}
                  currentUserCanCancel={order.can_cancel}
                  formattedCancelationContacts={
                    order.cancel_user_ids && formattedCancelationContacts(order, users)
                  }
                  order={order}
                  services={services}
                  user={currentUser}
                  paymentListEl={
                    pays_through_platform && (
                      <React.Fragment>
                        <span>{t("addPaymentInSettingsLabel")}</span>
                      </React.Fragment>
                    )
                  }
                  styles={styles}
                  hideChargeMessage={!pays_through_platform}
                  pays_through_platform={pays_through_platform}
                  paymentMethods={pays_through_platform && braintree_payment_methods}
                  hasPaymentMethod={has_payment_method}
                  paymentProviderTypes={fleet_payment_providers}
                  hideSavings={true}
                  hideRetailPricing={true}
                  hidePriceRange={isTBC(currentUser)}
                  hidePricing={[INITIATED_BY_USER, ACTIVE].includes(order.state)}
                  priceRanges={priceRanges}
                  canApprove={order.can_approve}
                  loadingSums={!!loadingSums}
                  onApprovalChange={this.onApprovalChange}
                  customerCommentsCharLimit={150}
                  rejectionReasonsDropdown={RejectionReasonsDropdown}
                  CreditCardDropdown={CreditCardDropdown}
                  SelectPaymentProvidersView={this.selectPaymentProvidersView()}
                  selectedPaymentProvider={selectedPaymentProvider}
                  wex_account_number={wex_account_number}
                  t={t}
                  i18n={i18n}
                  onVanitySubmit={this.onVanitySubmit}
                  isVanityLabelSubmitting={isVanityLabelSubmitting}
                  canEditVanity={!isDriver(currentUser)}
                  onApproversNotesSubmit={this.onApproversNotesSubmit}
                  isApprovalNotesSubmitting={isApprovalNotesSubmitting}
                  approverNotesCharLimit={500}
                  promotionalCodeLink={
                    <PromotionalCodeLink
                      shop_order_id={order.id}
                      onApprovalChange={this.onApprovalChange}
                      services={onApprovalChangeServices || services}
                      order={order}
                    />
                  }
                  continueToPayment={this.continueToPayment}
                  backToApprovals={this.backToApprovals}
                  paymentScreen={paymentScreen}
                  paymentListMethodsComponent={this.renderPayments()}
                  updateAllServices={this.updateAllServices}
                  approveAll={approveAll}
                  declinedAll={declinedAll}
                  orderStatus={getOrderStatus(order)}
                  loadingPaymentMethods={loadingPaymentMethods}
                  ApprovalsShowPaymentSectionShimmer={<ApprovalsShowPaymentSectionShimmer />}
                  renderVanityComponent={this.renderVanityComponent}
                  updateVanityForServiceId={updateVanityForServiceId}
                  vanityName={vanityName}
                  resetVanityData={this.resetVanityData}
                  renderViewmaintenanceHistoryLink={this.renderViewmaintenanceHistoryLink}
                  tireShopPlaceHolder={showTirePromotionBannerFlag == getSubdomain()}
                  currentUserSession={currentUserSession}
                  originator={order && order.originator}
                  subdomain={getSubdomain()}
                  tirePromotionBannerConfig={
                    !isEmpty(showTirePromotionBannerConfig) && showTirePromotionBannerConfig
                  }
                  renderViewReceiptButton={this.renderViewReceiptButton}
                  savingsComponent={this.savingsComponent}
                />

                {/*
                Only exposing approvals history if pays_through_platform, since the backend's
                calculation of the order totals _might_ be slightly different and need to adjust
                for those that don't pay through platform.
              */}
                {pays_through_platform && (
                  <ApprovalsHistory shopOrderId={this.props.match.params.id} />
                )}
                <ApproversNotesHistory shopOrderId={this.props.match.params.id} />
              </div>
            </div>
          </section>
        </div>
      )
    } else {
      return (
        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("reviewShopOrderLabel")}
          </Header>
          <Segment>{/* <ApprovalsEmptyState t={this.props.t} /> */}</Segment>
        </div>
      )
    }
  }

  render() {
    const { isLoading, isLoadingError, t } = this.props

    const { alertMessage, alertType, loadingSums, isVanityLabelSubmitting } = this.state
    return (
      <ApplicationLayout>
        {alertMessage && (
          <Alert
            message={alertMessage}
            type={alertType}
            clearStateAfterTimeout={() =>
              clearStateAfterTimeout(this.setState.bind(this), ALERT_MESSAGE)
            }
          />
        )}
        {isLoading &&
          !loadingSums &&
          !this.props.services &&
          (isFleetAdvise() ? (
            <ApprovalsShowShimmer />
          ) : (
            <LoadingThrobber visible={isLoading && !loadingSums} />
          ))}

        {/* <LoadingError visible={!isLoading && isLoadingError} /> */}

        {(!isLoading || loadingSums) && !isLoadingError && this.props.services && (
          <div>
            {this.approvalsInfoModal()}
            {this.renderContent()}
          </div>
        )}
      </ApplicationLayout>
    )
  }
} // class ApprovalsShow

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isVehicleMHComplete: state.vehicles.isVehicleMHComplete,
  isLoadingError: state.application.isLoadingError,
  users: state.users.users,
  order: state.shopOrders.shopOrder,
  services:
    state.shopOrders.shopOrder &&
    state.shopOrders.shopOrder.order_services &&
    state.shopOrders.shopOrder.order_services.filter(
      (service) => ![serviceStatuses["deleted"]].includes(service.status)
    ),
  priceRanges: state.shopOrders.priceRanges,
  currentUser: state.users.currentUser || state.application.userSessionData,
  currentUserSession: state.application.userSessionData,
  vehicle: state.shopOrders.shopOrder && state.shopOrders.shopOrder.vehicle,
  fleet: state.application.fleetSessionData || state.fleets.fleet
})

export default connect(mapStateToProps)(
  withTranslation([
    "shopOrders",
    "approvalsEmptyState",
    "approvalsForm",
    "approvalsInfoModal",
    "vehicle"
  ])(ApprovalsShow)
)
