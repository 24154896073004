import moment from "moment"
import i18n from "../utilities/i18n"
import { WEEK, MONTH, YEAR } from "./application"
import { Date_Format } from "./years"
import React from "react"
import { formatCurrency } from "../utilities/currency"
import { CollapsableServiceTable } from "../views/accounts/FMCReporting"
import { getFmcAndFleetId } from "../actions/sagas/reportingSaga"

const MANUAL_MAINTENANCE_HISOTRY = "ManualMaintenanceHistory"
const SHOP_ORDER = "ShopOrder"
const CAR_ADVISE = "CarAdvise"
const EXTERNAL = "External"

export function transactionReportColumnDefs() {
  return [
    {
      name: "index",
      label: "#",
      options: {
        setCellProps: () => ({ style: { width: "3%" } })
      }
    },
    ...(getFmcAndFleetId().isFMC
      ? [
          {
            name: "fleet_name",
            label: i18n.t("reports:fleetNameLabel"),
            options: {
              customBodyRender: (value) => {
                return (
                  <p className="fleet-name-column" data-tip={value} data-for={"default-tooltip"}>
                    {value}
                  </p>
                )
              }
            }
          }
        ]
      : []),
    { name: "shop_order", label: i18n.t("reports:shopOrderLabel") },
    { name: "shop_name", label: i18n.t("reports:shopNameLabel") },
    {
      name: "resource_type",
      label: i18n.t("reports:orderType"),
      options: {
        setCellProps: () => ({ style: { width: "3%" } }),
        customBodyRender: (value) =>
          value === MANUAL_MAINTENANCE_HISOTRY ? EXTERNAL : value === SHOP_ORDER ? CAR_ADVISE : ""
      }
    },
    {
      name: "order_date",
      label: i18n.t("reports:orderDateLabel"),
      options: {
        customBodyRender: (value) => moment(value, "MM-DD-YYYY").format(Date_Format.MM_DD_YY)
      }
    },
    { name: "vehicle", label: i18n.t("reports:vehicleNameLabel") },
    { name: "vin", label: i18n.t("reports:vehicleVinLabel") },
    {
      name: "other_id",
      label: i18n.t("reports:otherIdLabel"),
      options: {
        setCellProps: () => ({ style: { width: "4%" } })
      }
    },
    {
      name: "invoice_number",
      label: i18n.t("reports:invoiceNumberLabel")
    },
    {
      name: "services",
      label: i18n.t("reports:serviceLabel"),
      options: {
        customBodyRender: (value) => {
          const list = value.split(",")
          return <CollapsableServiceTable data={list} />
        },
        setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "800px" } })
      }
    },
    {
      name: "amount",
      label: i18n.t("reports:totalLabel"),
      options: {
        options: {
          setCellProps: () => ({ style: { width: "4%" } })
        },
        customBodyRender: (value) => formatCurrency(value)
      }
    },
    {
      name: "savings",
      label: i18n.t("reports:savings"),
      options: {
        options: {
          setCellProps: () => ({ style: { width: "4%" } })
        },
        customBodyRender: (value) => formatCurrency(value)
      }
    }
  ]
}

export const FILTER_OPTIONS = [
  {
    value: WEEK,
    label: i18n.t("reports:lastWeekLabel")
  },
  {
    value: MONTH,
    label: i18n.t("reports:lastMonthLabel")
  },
  {
    value: YEAR,
    label: i18n.t("reports:lastYearLabel")
  }
]

export function convertDateToString(filters) {
  if (!filters) {
    return ""
  }
  return {
    ...filters,
    appointment_datetime_to:
      filters.appointment_datetime_to && filters.appointment_datetime_to instanceof moment
        ? filters.appointment_datetime_to.format(Date_Format.YYYY_MM_DD)
        : filters.appointment_datetime_to,
    appointment_datetime_from:
      filters.appointment_datetime_from && filters.appointment_datetime_from instanceof moment
        ? filters.appointment_datetime_from.format(Date_Format.YYYY_MM_DD)
        : filters.appointment_datetime_from
  }
}

export const CUSTOMIZE_REPORT_OPTIONS = [
  {
    label: "Fleet name",
    value: "fleet_name"
  },
  {
    label: "VIN",
    value: "vin"
  },
  {
    label: "Shop order Number",
    value: "shop_order"
  },
  {
    label: "Other ID",
    value: "other_id"
  },
  {
    label: "Shop order date",
    value: "order_date"
  },
  {
    label: "Department number",
    value: "department_number"
  },
  {
    label: "Invoice number",
    value: "invoice_number"
  },
  {
    label: "Services",
    value: "services"
  },
  {
    label: "Vehicle",
    value: "vehicle"
  },
  {
    label: "Amount",
    value: "amount"
  },
  {
    label: "Savings",
    value: "savings"
  },
  {
    label: "Shop Name",
    value: "shop_name"
  },
  {
    label: "Shop Address",
    value: "shop_address"
  }
]

export const INCLUDE_ALL_COLUMNS = "Include all columns"
