import * as maintenanceHelpers from "../helpers/maintenanceHelpers"
import * as vehicleHelpers from "../helpers/vehicleHelpers"
import * as shopHelpers from "../helpers/shopHelpers"
import { PAID_STATUSES, COMPLETED } from "./statuses"

import * as moment from "moment-timezone"
import { AMERICA, Date_Format } from "./years"
import { KILOMETERS, MILES, NA } from "./vehicles"
import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"

export const ACTIVE = "active"
export const PAST = "past"
export const UPCOMING = "upcoming"
export const SCHEDULE_BUTTON_FIELD = "schedule_now"
export const REMIND_ME_BUTTON_FIELD = "remind_me_button"

export const MANUAL_MAINTENANCE_TYPE = "Manually Entered"
export const FLEETADVISE_MAINTENANCE_TYPE = "FleetAdvise"
export const OIL_CHANGE = "Oil Change"
export const FAULT_CODE_INSPECTION = "Fault Codes Inspection"
export const ORDER_ID = "id"

export function orderGridColumnDefs(
  page,
  servicesCellRenderer,
  schedulerCellRenderer,
  statusCellRenderer,
  reminderCellRenderer,
  t,
  downloadInvoiceRenderer
) {
  const widths = {
    xsmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    xlarge: 225,
    xxlarge: 250,
    xxxlarge: 300
  }

  let arr = [
    {
      headerName: t("vehicle:dateLabel"),
      field: "appointment_datetime",
      width: widths.medium,
      filter: "agDateColumnFilter",
      valueFormatter: (data) =>
        isFleetMaintenanceHubCanada() && data.value
          ? data.value.format(Date_Format.YYYY_MM_DD)
          : data.value
          ? data.value.format("L")
          : ""
    },
    {
      headerName: t("dashboard:vehicleLabel"),
      field: "vehicle",
      width: widths.xlarge
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.xlarge
    },
    {
      headerName: t("dashboard:otherIdLabel"),
      field: "other_id",
      width: widths.small
    },
    {
      headerName: t("dashboard:DepartmentNumber"),
      field: "department_number",
      width: widths.medium
    },
    {
      headerName: t("shops:shopLabel"),
      field: "shop",
      width: widths.medium,
      resizable: true
    },
    {
      headerName: t("settings:servicesLabel"),
      field: "services",
      width: widths.xxxlarge,
      resizable: true,
      cellRendererFramework: servicesCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada()
        ? t("dashboard:kilometersLabel")
        : t("orderMileageLabel"),
      field: "mileage",
      width: widths.medium
    },
    {
      headerName: "PO #",
      field: "po_number",
      width: widths.medium
    },
    {
      headerName: t("vehicle:costLabel"),
      field: "total",
      width: widths.medium,
      valueFormatter: (data) =>
        isFleetMaintenanceHubCanada() && data.value
          ? `$${data.value} CAD`
          : data.value
          ? `$${data.value}`
          : ""
    },
    {
      headerName: "Saving #",
      field: "saving",
      width: widths.medium,
      valueFormatter: (data) =>
        isFleetMaintenanceHubCanada() && data.value
          ? `$${data.value} CAD`
          : data.value
          ? `$${data.value}`
          : ""
    },
    {
      headerName: t("invoiceLabel"),
      field: "invoice_number",
      width: widths.medium
    },
    {
      headerName: t("maintenanceTypeLabel"),
      field: "maintenance_type",
      width: widths.medium
    }
  ]

  if (page !== PAST) {
    arr.splice(1, 0, {
      headerName: t("timeLabel"),
      field: "time",
      width: widths.medium
    })
  } else if (page === PAST) {
    arr.splice(1, 0, {
      headerName: "Order #",
      field: "id",
      resizable: true,
      cellRendererFramework: downloadInvoiceRenderer
    })

    arr.splice(2, 0, {
      headerName: t("services:approverLabel"),
      field: "approver",
      width: widths.small
    })
  }

  arr.splice(arr.length - 1, 0, {
    headerName: t("statusLabel"),
    field: "status",
    width: widths.xxlarge,
    cellRendererFramework: statusCellRenderer
  })

  return arr
}

export function orderGridRowData(shopOrders, language) {
  return shopOrders.map((order) => {
    const vehicle = order.vehicle
    const order_mileage = isFleetMaintenanceHubCanada()
      ? order.order_services &&
        order.order_services.length > 0 &&
        order.order_services[0].service_kilometers
      : order.order_services &&
        order.order_services.length > 0 &&
        order.order_services[0].service_miles
    return {
      appointment_datetime:
        order.appointment_datetime.length === 10
          ? moment(order.appointment_datetime)
          : moment(order.appointment_datetime).tz(AMERICA).startOf("day"),
      vehicle: vehicleHelpers.formattedName(vehicle),
      shop: shopHelpers.formattedName(order.shop, order.custom_shop_name),
      other_id: vehicle.other_id || NA,
      department_number: vehicle.department_number || NA,
      po_number: order.po_number,
      status: {
        status: PAID_STATUSES.indexOf(order.status) === -1 ? order.status : COMPLETED,
        id: order.id
      },
      time: order.appointment_time_pretty,
      invoice_number: order.invoice_number,
      services: order.order_services
        .filter((os) => os.status !== "rejected" && os.status !== "deleted")
        .map((s) => {
          if (s.vanity_label != null && s.vanity_label.length > 0) {
            return s.vanity_label
          }
          return `${s.name}${s.position ? ` (${s.position})` : ""}`
        })
        .join(", "),
      total: order.total && parseFloat(order.total).toFixed(2),
      // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
      id: order.id,
      maintenance_type: order.maintenance_type || FLEETADVISE_MAINTENANCE_TYPE,
      vin: vehicle.vin,
      mileage:
        order_mileage ||
        order.miles ||
        (order.order_services[0] && order.order_services[0].miles) ||
        vehicle.miles,
      vehicle_id: vehicle.id,
      language,
      saving: order.saving && parseFloat(order.saving).toFixed(2),
      last_approval_date: order.last_approval_date,
      approver: order.approver
    }
  })
}

export function maintGridColumnDefs(
  page,
  servicesCellRenderer,
  schedulerCellRenderer,
  statusCellRenderer,
  reminderCellRenderer,
  t
) {
  const widths = {
    xsmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    xlarge: 225,
    xxlarge: 250,
    xxxlarge: 300
  }

  return [
    {
      headerName: t("dashboard:vehicleLabel"),
      field: "vehicle",
      width: widths.xlarge
    },
    {
      headerName: t("dashboard:otherIdLabel"),
      field: "other_id",
      width: widths.medium
    },
    {
      headerName: t("dashboard:DepartmentNumber"),
      field: "department_number",
      width: widths.medium
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.medium
    },
    {
      headerName: isFleetMaintenanceHubCanada() ? t("kilometersLabel") : "Miles",
      field: isFleetMaintenanceHubCanada() ? KILOMETERS : MILES,
      width: widths.small
    },
    {
      headerName: t("dueAtLabel"),
      field: "due_at",
      width: widths.small
    },
    {
      headerName: t("dueStatusLabel"),
      field: "due_status",
      width: widths.medium,
      filter: "agTextColumnFilter"
    },
    {
      headerName: t("settings:servicesLabel"),
      field: "services",
      width: widths.xxxlarge,
      resizable: true,
      cellRendererFramework: servicesCellRenderer
    },
    {
      headerName: "",
      field: SCHEDULE_BUTTON_FIELD,
      width: widths.xxlarge,
      resizable: true,
      cellRendererFramework: schedulerCellRenderer
    },
    {
      headerName: "",
      field: REMIND_ME_BUTTON_FIELD,
      width: widths.xlarge,
      resizable: true,
      cellRendererFramework: reminderCellRenderer
    },
    {
      headerName: "Snoozed Date",
      field: "snoozed_status",
      width: widths.medium
    }
  ]
}

export function upcomingIntervalRowData(maintenancesAndVehicles, t) {
  const isDomainFleetMaintenanceHubCanada = isFleetMaintenanceHubCanada()
  return maintenancesAndVehicles
    .map(({ vehicle, maintenance, type }) => ({
      [t("vehicle:vinLabel")]: vehicle && vehicle.vin,
      [t("vehicelLabel")]: vehicleHelpers.formattedName(vehicle),
      [t("vehicle:otherIDLabel")]: (vehicle && vehicle.other_id) || NA,
      [t("dashboard:DepartmentNumber")]: (vehicle && vehicle.department_number) || NA,
      [isDomainFleetMaintenanceHubCanada ? [t("kilometersLabel")] : "Miles"]:
        isDomainFleetMaintenanceHubCanada
          ? vehicle && vehicle.kilometers
          : vehicle && vehicle.miles,
      [t("dueAtLabel")]: vehicle && vehicle.next_maintenance_date,
      [t("dueStatusLabel")]: type,
      [t("servicesLabel")]: maintenance
        .map((s) => `${s.name}${s.position ? ` (${s.position})` : ""}`)
        .join(",")
    }))
    .sort((a, b) =>
      isInteger(a.due_at) && isInteger(b.due_at)
        ? a.due_at - b.due_at
        : isInteger(a.due_at)
        ? -1
        : 1
    )
}

export function mainGridRowData(maintenancesAndVehicles, language, vehicleReminders) {
  const isDomainFleetMaintenanceHubCanada = isFleetMaintenanceHubCanada()
  return maintenancesAndVehicles
    .map(({ vehicle, maintenance, type }) => ({
      id: vehicle && vehicle.id,
      vin: vehicle && vehicle.vin,
      vehicle: vehicle && vehicleHelpers.formattedName(vehicle),
      other_id: (vehicle && vehicle.other_id) || NA,
      department_number: (vehicle && vehicle.department_number) || NA,
      [isDomainFleetMaintenanceHubCanada ? KILOMETERS : MILES]: isDomainFleetMaintenanceHubCanada
        ? vehicle && vehicle.kilometers
        : vehicle && vehicle.miles,
      due_at: (vehicle && vehicle.next_maintenance_date) || NA,
      due_status: type,
      services: maintenance
        .map((s) => `${s.name}${s.position ? ` (${s.position})` : ""}`)
        .join(", "),
      schedule_now: { maintenance: maintenance, vehicle: vehicle },
      remind_me_button: { maintenance: maintenance, vehicle: vehicle },
      snoozed_status: maintenanceHelpers.getReminderDate(
        vehicleReminders,
        isDomainFleetMaintenanceHubCanada,
        vehicle && vehicle.id
      )
    }))
    .sort((a, b) =>
      isInteger(a.due_at) && isInteger(b.due_at)
        ? a.due_at - b.due_at
        : isInteger(a.due_at)
        ? -1
        : 1
    )
}

function isInteger(n) {
  return n % 1 === 0
}
