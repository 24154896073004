import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { useEffect, useState } from "react"
import MontserratRegular from "../../assets/fonts/Montserrat-Regular.ttf"
import MontserratBold from "../../assets/fonts/Montserrat-Bold.ttf"
import MontserratSemiBold from "../../assets/fonts/Montserrat-SemiBold.ttf"
import MontserratItalic from "../../assets/fonts/Montserrat-Italic.ttf"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { COMPLETED, PAID } from "../../constants/statuses"
import { navigationPaths } from "../../constants/paths"
import { isDTCAFleet, isFleetAdvise, isFleetcor, isZubie } from "../../helpers/affiliationHelpers"

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratRegular
    },
    {
      src: MontserratBold,
      fontWeight: "bold"
    },
    {
      src: MontserratSemiBold,
      fontWeight: "semibold"
    },
    {
      src: MontserratItalic,
      fontStyle: "italic"
    }
  ]
})
Font.registerHyphenationCallback((word) => [word])

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 0,
    fontSize: 10,
    fontFamily: "Montserrat",
    paddingBottom: 40
  },
  headerBackgroundColor: {
    fleetadvise: {
      backgroundColor: "#282F37"
    },
    zubie: {
      backgroundColor: "#000000"
    },
    fleetcor: {
      backgroundColor: "#000000"
    },
    discounttire: {
      backgroundColor: "#F0F2F4"
    }
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: getSubdomain() === "discounttire" ? "black" : "white",
    padding: 20,
    marginBottom: 20,
    alignItems: "center"
  },
  table: {
    display: "table",
    width: "auto",
    borderColor: "#000",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    flexDirection: "row"
  },
  tableCol: {
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    padding: 5
  },
  tableCell: {
    fontSize: 8,
    textAlign: "right"
  },
  total: {
    marginTop: 10,
    fontSize: 12,
    fontWeight: "bold"
  },
  subTotalSection: {
    textAlign: "right",
    marginTop: 10
  },
  infoSection: {
    backgroundColor: "#f4f4f4",
    flexGrow: "1",
    padding: "2px"
  },
  infoLabel: { flexDirection: "row", flexBasis: "48%", gap: "10px", alignItems: "center" },
  fontBold: { fontWeight: "bold" },
  fontSemiBold: { fontWeight: "semibold" },
  section: {
    marginBottom: 10
  },
  sectionHeader: {
    fontWeight: "bold",
    marginBottom: 0,
    fontSize: 12
  },
  separator: {
    marginVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E5E9"
  },
  shopInformation: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    marginBottom: "2px",
    alignItems: "center"
  },
  shopInformationLabel: { flexBasis: "35%", fontWeight: "semibold" },
  amountField: {
    display: "flex",
    flexDirection: "column",
    marginVertical: "2px",
    justifyContent: "space-between",
    gap: "10px"
  },
  piggyBank: {
    backgroundColor: "#282F37",
    padding: "0 15",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px"
  },
  informationSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  fleetadvise: { width: "121px", height: "31" },
  zubie: { width: "90", height: "40" },
  fleetcor: { width: "162", height: "33" },
  discounttire: { width: "223", height: "35" },
  piggyBankBgColor: {
    discounttire: {
      backgroundColor: "#1D2433"
    },
    fleetcor: {
      backgroundColor: "#E1261C"
    },
    fleetadvise: {
      backgroundColor: "#1D2433"
    },
    zubie: {
      backgroundColor: "#5742E0"
    }
  },
  headerReceipt: { display: "flex", flexDirection: "column", alignItems: "flex-end" }
})

export const InvoicePDF = ({ order, fleet }) => {
  const shop = order.shop
  const vehicle = order.vehicle
  const [total, setTotal] = useState(null)

  useEffect(() => {
    let totalLabourPrice = 0
    let totalPartsPrice = 0
    order.order_services.forEach((service) => {
      totalLabourPrice = Number(service.labor_total ?? 0) + totalLabourPrice
      totalPartsPrice = Number(service.override_parts_total ?? 0) + totalPartsPrice
    })
    setTotal({ totalPartsPrice, totalLabourPrice })
  }, [order])

  return (
    <Document title={"Invoice-" + order.id} subject={"Invoice-" + order.id}>
      <Page style={styles.page}>
        <Header order={order} />
        <View style={{ paddingHorizontal: 20, paddingBottom: 40 }}>
          <Receipt order={order} />
          <View style={styles.informationSection}>
            <ShopInformation shop={shop} fleet={fleet} />
            <VehicleInformation vehicle={vehicle} />
          </View>
          <LineItems services={order.order_services} />
          <PaymentSection order={order} total={total} />
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

const Header = ({ order }) => (
  <View style={{ ...styles.header, ...styles.headerBackgroundColor[getSubdomain()] }} fixed>
    <View>
      <Image
        src={`/assets/white_label/logos/${getSubdomain()}.png`}
        style={styles[getSubdomain()]}
      />
    </View>
    <View
      style={styles.headerReceipt}
      render={({ pageNumber, totalPages }) => (
        <>
          <Text style={{ fontWeight: "bold", fontSize: "16", textAlign: "right" }}>RECEIPT</Text>
          <Text style={{ fontWeight: "bold", fontSize: "14", textAlign: "right" }}>
            Order #{order.id}
          </Text>
          <Text style={{ fontWeight: "bold", fontSize: "10", textAlign: "right" }}>
            {`Page ${pageNumber} of ${totalPages}`}
          </Text>
        </>
      )}
    />
  </View>
)

const Receipt = ({ order }) => (
  <View style={{ ...styles.section, width: "48%" }}>
    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>RECEIPT DATE</Text>
      <Text
        style={{
          ...styles.infoSection,
          fontWeight: "bold"
        }}
      >
        {convertToDateFormat(order.appointment_datetime)}
      </Text>
    </View>
    <View></View>
  </View>
)

const Footer = () => (
  <View
    style={{ position: "absolute", bottom: "0px", width: "100%", padding: "10px" }}
    fixed={true}
    render={({ pageNumber, totalPages }) => (
      <View
        style={{
          fontSize: "8px",
          display: "flex",
          flexDirection: "row",
          justifyContent: `${pageNumber === totalPages ? "space-between" : "flex-end"}`,
          fontStyle: "italic",
          alignItems: "flex-end"
        }}
      >
        {pageNumber === totalPages && (
          <View>
            <Text>*Shop payment processed via CarAdvise.</Text>
            <Text>
              *The total amount shown may include price adjustments not reflected in the individual
              part or labor line items.
            </Text>
          </View>
        )}
        <Text style={{ textAlign: "right" }}>{`Page ${pageNumber} of ${totalPages}`}</Text>
      </View>
    )}
  />
)

const LineItems = ({ services }) => {
  return (
    <View style={styles.section}>
      <View style={styles.table}>
        <View fixed>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "80%" }}>
              <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "left" }}>SERVICES</Text>
            </View>
            <View style={{ ...styles.tableCol, ...styles.fontSemiBold, width: "12%" }}>
              <Text style={styles.tableCell}>Part Qty</Text>
            </View>
            <View style={{ ...styles.tableCol, ...styles.fontSemiBold, width: "12%" }}>
              <Text style={styles.tableCell}>Part Cost</Text>
            </View>
            <View style={{ ...styles.tableCol, ...styles.fontSemiBold, width: "14%" }}>
              <Text style={styles.tableCell}>Labor Cost</Text>
            </View>
            <View style={{ ...styles.tableCol, ...styles.fontSemiBold, width: "12%" }}>
              <Text style={styles.tableCell}>Total Cost</Text>
            </View>
          </View>
          <View style={{ ...styles.separator, marginVertical: "1" }} /> {/* Horizontal line */}
        </View>
        {services.map((service) => {
          return (
            <>
              <View style={{ ...styles.tableRow, alignItems: "flex-start" }} wrap={false}>
                <View style={{ ...styles.tableCol, width: "80%" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginVertical: "2px",
                      gap: "5px"
                    }}
                  >
                    <Text style={{ ...styles.fontSemiBold, flexBasis: "30%", fontSize: 10 }}>
                      Service Name
                    </Text>
                    <Text style={{ fontSize: 9 }}>{service.name}</Text>
                  </View>
                  {service.description && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginVertical: "2px",
                        gap: "5px"
                      }}
                    >
                      <Text style={{ ...styles.fontSemiBold, flexBasis: "30%", fontSize: 10 }}>
                        Description
                      </Text>
                      <Text style={{ fontSize: 9 }}>{service.description}</Text>
                    </View>
                  )}
                  {service.shop_comments && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginVertical: "2px",
                        gap: "5px"
                      }}
                    >
                      <Text
                        style={{
                          ...styles.fontSemiBold,
                          width: "109px",
                          fontSize: 10,
                          display: "inline-block"
                        }}
                      >
                        Shop Comments
                      </Text>
                      <Text
                        style={{
                          fontSize: 9,
                          maxWidth: "250px",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          hyphens: "none",
                          display: "inline-block"
                        }}
                      >
                        {service.shop_comments.trim()}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={{ ...styles.tableCol, width: "12%" }}>
                  <Text style={styles.tableCell}>{service.quantity ?? "-"}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "12%" }}>
                  <Text style={styles.tableCell}>
                    {service.override_parts_total
                      ? `$${Number(service.override_parts_total).toFixed(2)}`
                      : "-"}
                  </Text>
                </View>
                <View style={{ ...styles.tableCol, width: "14%" }}>
                  <Text style={styles.tableCell}>
                    {service.labor_total ? `$${Number(service.labor_total).toFixed(2)}` : "-"}
                  </Text>
                </View>
                <View style={{ ...styles.tableCol, width: "12%" }}>
                  <Text style={styles.tableCell}>
                    {service.override_total ? `$${Number(service.override_total).toFixed(2)}` : "-"}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.separator, marginVertical: "0" }} /> {/* Horizontal line */}
            </>
          )
        })}
      </View>
    </View>
  )
}

const ShopInformation = ({ shop, fleet }) => (
  <View style={{ ...styles.section, flexBasis: "48%", width: "50%" }}>
    <Text style={{ ...styles.sectionHeader, ...styles.fontBold }}>SHOP INFORMATION</Text>
    <View style={{ ...styles.separator }} />
    <View style={styles.shopInformation}>
      <Text style={{ ...styles.shopInformationLabel, flexBasis: "55%", alignSelf: "flex-start" }}>
        NAME
      </Text>
      <Text style={{ ...styles.infoSection, width: "100%" }}>{shop.name}</Text>
    </View>
    <View style={{ ...styles.shopInformation, alignItems: "flex-start" }}>
      <Text style={{ ...styles.shopInformationLabel, flexBasis: "55%" }}>ADDRESS</Text>
      <Text style={{ ...styles.infoSection, width: "100%" }}>
        {[shop.address_line1, shop.city, shop.state, shop.zip].join(", ")}
      </Text>
    </View>
    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>PHONE</Text>
      <Text style={styles.infoSection}>{shop.main_phone_number}</Text>
    </View>

    <Text style={{ ...styles.sectionHeader, ...styles.fontBold, marginTop: "20" }}>
      FLEET INFORMATION
    </Text>
    <View style={{ ...styles.separator }} />
    <View style={styles.shopInformation}>
      <Text style={{ ...styles.shopInformationLabel, flexBasis: "55%", alignSelf: "flex-start" }}>
        FLEET NAME
      </Text>
      <Text style={{ ...styles.infoSection, width: "100%" }}>{fleet?.name}</Text>
    </View>
  </View>
)

const VehicleInformation = ({ vehicle }) => (
  <View style={{ ...styles.section, flexBasis: "48%" }}>
    <Text style={{ ...styles.sectionHeader, ...styles.fontBold }}>VEHICLE INFORMATION</Text>
    <View style={{ ...styles.separator }} />

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>VIN</Text>
      <Text style={styles.infoSection}>{vehicle.vin ?? " "}</Text>
    </View>

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>LICENSE PLATE</Text>
      <Text style={styles.infoSection}>{vehicle.license_plate_number ?? " "}</Text>
    </View>

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>YEAR</Text>
      <Text style={styles.infoSection}>{vehicle.year ?? " "}</Text>
    </View>

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>MAKE</Text>
      <Text style={styles.infoSection}>{vehicle.make ?? " "}</Text>
    </View>

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>MODEL</Text>
      <Text style={styles.infoSection}>{vehicle.model ?? " "}</Text>
    </View>

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>MILEAGE</Text>
      <Text style={styles.infoSection}>{Number(vehicle.miles).toLocaleString() ?? " "}</Text>
    </View>

    {vehicle.name_of_vehicle && isFleetcor() && (
      <View style={styles.shopInformation}>
        <Text style={styles.shopInformationLabel}>CARD NAME</Text>
        <Text style={styles.infoSection}>{vehicle.name_of_vehicle}</Text>
      </View>
    )}

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>DEPARTMENT #</Text>
      <Text style={styles.infoSection}>{vehicle.department_number ?? " "}</Text>
    </View>

    {!isFleetcor() && (
      <View style={styles.shopInformation}>
        <Text style={styles.shopInformationLabel}>OTHER ID</Text>
        <Text style={styles.infoSection}>{vehicle.other_id?.trim() ? vehicle.other_id : " "}</Text>
      </View>
    )}

    <View style={styles.shopInformation}>
      <Text style={styles.shopInformationLabel}>DRIVER NAME</Text>
      <Text style={styles.infoSection}>{vehicle.driver_name ?? " "}</Text>
    </View>
  </View>
)

const PricingInformation = ({ order, total }) => (
  <>
    <View style={{ flexBasis: "50%" }}>
      {!isFleetcor() &&
        (order.braintree_payment_methods?.length > 0 ||
          order.check_payment_method_of_previous_transaction) && (
          <View style={{ padding: "10px", border: "2px solid #E2E5E9", borderRadius: "4px" }}>
            <View
              style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2px" }}
            >
              <Text style={{ fontWeight: "bold", fontSize: "10px" }}>Payment Method:</Text>
              <Text>
                {paymentType(
                  order.braintree_payment_methods?.[0]?.type ||
                    order.check_payment_method_of_previous_transaction
                )}
              </Text>
            </View>
            {order.braintree_payment_methods?.[0]?.last_4 && (
              <View
                style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2px" }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "10px"
                  }}
                >
                  {order.braintree_payment_methods[0].type === "ach"
                    ? "Account Number:"
                    : "Card Number:"}
                </Text>
                <Text>{paymentNumber(order.braintree_payment_methods[0])}</Text>
              </View>
            )}
          </View>
        )}
      {isFleetcor() && (
        <View style={{ padding: "10px", border: "2px solid #E2E5E9", borderRadius: "4px" }}>
          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2px" }}>
            <Text style={{ fontWeight: "bold", fontSize: "10px" }}>Payment Method:</Text>
            <Text>Fuelman Account</Text>
          </View>
        </View>
      )}
    </View>
    <View
      style={{
        textAlign: "right",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "20px"
      }}
    >
      <View style={{ ...styles.amountField, alignItems: "flex-end" }}>
        <Text style={{ ...styles.fontBold, textAlign: "right", fontWeight: "semibold" }}>
          Labor
        </Text>
        <Text style={{ ...styles.fontBold, fontWeight: "semibold" }}>Parts</Text>
        <Text style={{ ...styles.fontBold, fontWeight: "semibold" }}>Sub-Total</Text>
        <Text style={{ ...styles.fontBold, fontWeight: "semibold" }}>Tax</Text>
        <Text style={{ ...styles.fontBold, fontWeight: "semibold" }}>Convenience Fee</Text>
        <Text style={{ ...styles.fontBold, fontWeight: "semibold" }}>Total</Text>
      </View>
      <View style={{ ...styles.amountField, alignItems: "flex-end" }}>
        <Text>${Number(total?.totalLabourPrice ?? 0).toFixed(2)}</Text>
        <Text>${Number(total?.totalPartsPrice ?? 0).toFixed(2)}</Text>
        <Text>${Number(order.pre_tax_sub_total - order.transaction_fee || 0).toFixed(2)}</Text>
        <Text>${Number(order.tax_amount).toFixed(2)}</Text>
        <Text>${Number(order.transaction_fee).toFixed(2)}</Text>
        <Text>${Number(order.estimated_total).toFixed(2)}</Text>
      </View>
    </View>
  </>
)

const PaymentSection = ({ order, total }) => (
  <View wrap={false}>
    <Text style={{ fontWeight: "bold", fontSize: "12px", marginBottom: "5px" }}>
      PAYMENT DETAILS:
    </Text>
    <View
      style={{
        marginLeft: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
      }}
    >
      <PricingInformation order={order} total={total} />
    </View>
    <View style={{ ...styles.separator }} />
    <View
      style={{
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "20px"
      }}
    >
      <Text>TOTAL AMOUNT PAID TO CARADVISE*</Text>
      <Text>${Number(order.estimated_total).toFixed(2)}</Text>
    </View>

    {Number(order.total_savings) > Number(order.transaction_fee) && (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1px",
          fontSize: "14px",
          backgroundColor: "#F1F1F1",
          borderRadius: "4px",
          marginTop: "50px",
          justifyContent: "space-between"
        }}
      >
        <View style={{ padding: 20, width: "90%" }}>
          <Text style={{ fontWeight: "bold", fontSize: 14 }}>
            You saved ${order.total_savings}
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {" "}
              by using CarAdvise {isFleetAdvise() && "for Business."}
              {!isFleetAdvise() && "through " + getAffiliation()}
            </Text>
          </Text>
        </View>
        <View style={{ ...styles.piggyBank, ...styles.piggyBankBgColor[getSubdomain()] }}>
          <Image
            src={"/assets/images/PiggyBank.png"}
            style={{ width: "60px", height: "50px", marginTop: "5px" }}
          />
        </View>
      </View>
    )}
  </View>
)

const ShowInvoice = ({ order, dispatch, match, fleet, history }) => {
  useEffect(() => {
    dispatch({
      type: "SHOP_ORDER_LOAD_DETAILS_SAGA",
      payload: {
        shopOrderId: match.params.id
      }
    })
  }, [])

  useEffect(() => {
    if (order && order.status && ![COMPLETED, PAID].includes(order.status)) {
      history.push(navigationPaths.approvalsShow(order.id))
    }
  }, [order])
  useEffect(() => {
    dispatch({
      type: "SHOP_ORDER_LOAD_SAGA",
      payload: {
        shopOrderId: match.params.id,
        priceAsApprovalsLogic: true
      },
      callback: async (_, data) => {
        dispatch({
          type: "VEHICLE_LOAD_SAGA",
          payload: {
            vehicleId: data.vehicle_id,
            options: {
              search: true
            }
          },
          callback: async (vehicleData) => {
            dispatch({
              type: "FLEET_LOAD_SAGA",
              payload: { fleetId: vehicleData.fleet_id }
            })
          }
        })
      }
    })
  }, [])

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      {order && order.shop && <InvoicePDF order={order} fleet={fleet} />}
    </PDFViewer>
  )
}

const paymentType = (payType) => {
  switch (payType) {
    case "ach":
      return "ACH"
    case "credit_card":
      return "Card Payment"
    case "Fleetcor":
      return "Fuelman Account"
    case "Wex":
      return "Wex"
    default:
      return ""
  }
}

const paymentNumber = (payment) => {
  switch (payment.type) {
    case "ach":
      return `*****${payment.last_4}`
    case "credit_card":
      return `**** **** **** ${payment.last_4}`
    default:
      return ""
  }
}

const getAffiliation = () => {
  if (isFleetcor()) return "Fuelman Maintenance."
  if (isZubie()) return "Zubie Smart Maintenance."
  if (isDTCAFleet()) return "Discount Tire Fleet Services."
  return ""
}

function convertToDateFormat(dateString) {
  const date = new Date(dateString)

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return null // or throw an error
  }

  // Extract components in UTC to prevent time zone shifts
  const month = String(date.getUTCMonth() + 1).padStart(2, "0")
  const day = String(date.getUTCDate()).padStart(2, "0")
  const year = date.getUTCFullYear()

  return `${month}/${day}/${year}`
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.application.isLoading,
    isVehicleMHComplete: state.vehicles.isVehicleMHComplete,
    order: state.shopOrders.shopOrder,
    vehicle: state.shopOrders.shopOrder && state.shopOrders.shopOrder.vehicle,
    fleet: state.application.fleetSessionData || state.fleets.fleet
  }
}

export default connect(mapStateToProps)(
  withTranslation([
    "shopOrders",
    "approvalsEmptyState",
    "approvalsForm",
    "approvalsInfoModal",
    "vehicle"
  ])(ShowInvoice)
)
